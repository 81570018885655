import React, { useState, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { API_URL } from "../../Common/constants";
import { withRouter } from "react-router-dom";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token) {
      props.history.push("/sports");
    }
  }, []);

  const handleCall = (e) => {
    e.preventDefault();
    let data = {};
    data.username = username;
    data.password = password;

    axios
      .post(`${API_URL}/v1/cashier/login`, data)
      .then((res) => {
        const users = res.data;
        localStorage.setItem("users", JSON.stringify(users.user));
        sessionStorage.setItem("token", users._token);
        props.history.push("/sports");
      })
      .catch((errors) => {
        errors?.response?.data.errors.message.map((error) => {
          setErrors(error);
        });
      });
  };

  return (
    <>
      <div className="account-pages my-5 pt-sm-5 main-back">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="overflow-hidden card">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to PRIME.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end col">
                      <img
                        src="http://dashboard.betstamp.site/static/media/profile-img.43b59e59.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-0 card-body">
                  <div>
                    <a className="auth-logo-light" href="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src="http://dashboard.betstamp.site/static/media/logo.e5ecb8e1.png"
                            alt=""
                            className="rounded-circle"
                            height={34}
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                  <form
    noValidate
    className="form-horizontal av-invalid"
    onSubmit={(e) => {
      handleCall(e);
    }}
  >
    {/* Username Field */}
    <div className="mb-3">
      <div className="text-danger form-group">
        <label htmlFor="username" className>
          Username:
        </label>
        <input
          name="text"
          placeholder="Enter username"
          required
          id="username"
          type="text"
          maxLength={12} // Set max chars to 12
          onChange={(e) => {
            const value = e.target.value;
            // Allow only alphabetic characters, digits, and spaces
            const regex = /^[a-zA-Z0-9 ]*$/;
            if (regex.test(value)) {
              setUsername(value);
            }
          }}
          value={username}
          className="form-control is-touched is-pristine av-invalid form-control"
        />
        <div className="invalid-feedback">{errors.username}</div>
      </div>
    </div>

    {/* Password Field */}
    <div className="mb-3">
      <div className="form-group">
        <label htmlFor="password" className>
          Password:
        </label>
        <input
          name="password"
          required
          placeholder="Enter Password"
          id="password"
          type="password"
          maxLength={12} // Set max chars to 12
          onChange={(e) => {
            const value = e.target.value;
            // Allow only alphabetic characters, digits, and spaces
            const regex = /^[a-zA-Z0-9 ]*$/;
            if (regex.test(value)) {
              setPassword(value);
            }
          }}
          value={password}
          className="form-control is-touched is-pristine av-invalid form-control"
        />
      </div>
    </div>

    {/* Submit Button */}
    <div className="mt-3 d-grid">
      <button className="btn btn-primary btn-block" type="submit">
        Log In
      </button>
    </div>
  </form>
</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Login);
