import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import {
  cancelTicketCall,
  getTicketBetData,
  getUserInfo,
  payTicketCall,
} from "../../Components/Common/repository";
import DataTable from "react-data-table-component";
import toastr from "toastr";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { setBets } from "../../store/Betslip/actions";
import { setBalance } from "../../store/Cashbalance/action";
import { setBookingID } from "../../store/BookingId/action";

export const Sidebar = () => {
  const [modalData, setModalsData] = useState([]);
  const [successPin, setSuccessPin] = useState(false);

  let dispatch = useDispatch();
  const Balance = useSelector((state) => state?.Cashbalance?.balance ?? 0);

  const callPayTicket = (id) => {
    getTicketBetData(id).then((res) => {
      localStorage.removeItem("book_bet_detail");
      if (res?.data?.data?.[0]) {
        if (res?.data?.is_ticket) {
          setModalsData(res?.data?.data[0] ?? []);
          $("#exampleModalCenter").modal("show");
        } else {
          if (Array.isArray(res?.data?.data)) {
            const betTicketDetails = res?.data?.data;

            let BetOdd = betTicketDetails
              .map((bet) => {
                return _getPlacedBet(bet.fixture_markets);
              })
              .map((bet) => {
                let sendBet = {};
                sendBet._id = bet._id;
                return sendBet;
              });
            let bookDetail = {};
            bookDetail.booking_id = id;
            bookDetail.odds = BetOdd;
            localStorage.setItem("book_bet_detail", JSON.stringify(bookDetail));

            dispatch(setBookingID(id));
            dispatch(setBets(res?.data?.data));
          }
        }
      } else {
     
        if (res?.data?.message) {
          toastr.error(res?.data?.message);
        } else {
          toastr.error("Id not exists!!!");
        }
      }
    });
  };
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;

  useEffect(() => {
    getUserInfo().then((res) => {
      dispatch(setBalance(res?.data?.data?.cash_balance));
    });
  }, []);

  const _getPlacedBet = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return odds[0];
      }
    }
  };
  const cancelTicket = (id) => {
    cancelTicketCall(id)
      .then((res) => {
        toastr.success(res?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(function (response) {
        toastr.error(response?.response?.data?.error);
      });
  };

  function diffMinutes(date1) {
    var currentTime = new Date().toISOString();
    const d1 = new Date(date1).getTime();
    const d2 = new Date(currentTime).getTime();
    return Math.round((d2 - d1) / 60000); // Can use Math.floor or Math.ceil depends up to you
  }

  const payTicket = (id, pin) => {
    if (pin.trim().length == 0) {
      toastr.error(`Please enter pin!!!`);
      return;
    } else {
      payTicketCall(id, pin)
        .then((res) => {
          $(".pin-textbox").val("");
          toastr.success(res?.data?.message);
          setSuccessPin(true);
        })
        .catch(function (response) {
          toastr.error(response?.response?.data?.error);
        });
    }
  };
  $(document).on("hide.bs.modal", "#exampleModalCenter1", function () {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  });
  useEffect(() => {
    if (successPin) {
      $("#exampleModalCenter").modal("hide");
      $("#exampleModalCenter1").modal("show");
    } else {
      $("#exampleModalCenter1").modal("hide");
    }
  }, [successPin]);

  const modalColumns = [
    {
      name: "Time",
      selector: (row) => (
        <>
          {" "}
          {new Date(Date.parse(row?.created_at)).toLocaleDateString(
            "en-GB"
          )}{" "}
          {new Date(Date.parse(row?.created_at)).toLocaleTimeString()}
        </>
      ),
      sortable: true,
    },
    {
      name: "Event",
      selector: (row) => (
        <>
          {row.odd ? row.odd.fixture_market.fixture.tournament.sport.name : ""}
          <img
            src={`/assets/images/information.png`}
            id={`addtooltip${row?.id}`}
            className="tooltipCls"
          />
          <UncontrolledTooltip
            placement="right"
            target={`addtooltip${row?.id}`}
            style={{ width: "100%" }}
          >
            <p style={{ "margin-bottom": "0px", "text-align": "left" }}>
              Sport:{" "}
              {row.odd
                ? row.odd.fixture_market.fixture.tournament.sport.name
                : ""}
            </p>
            <p style={{ "margin-bottom": "0px", "text-align": "left" }}>
              Location:{" "}
              {row.odd
                ? row.odd.fixture_market.fixture.tournament.category.name
                : ""}
            </p>
            <p style={{ "margin-bottom": "0px", "text-align": "left" }}>
              League:{" "}
              {row.odd ? row.odd.fixture_market.fixture.tournament.name : ""}
            </p>
            <p style={{ "margin-bottom": "0px", "text-align": "left" }}>
              Event:{" "}
              {row.odd
                ? row.odd.fixture_market.fixture.competitors[0].name + " vs"
                : ""}{" "}
              {row.odd
                ? row.odd.fixture_market.fixture.competitors[1].name
                : ""}
            </p>
          </UncontrolledTooltip>
        </>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => <>{row.bets?.length == 1 ? "Single" : "Multiple"}</>,
      sortable: true,
    },
    {
      name: "Fixture",
      selector: (row) => (
        <>
          {row.odd
            ? row?.odd?.fixture_market?.fixture?.competitors[0]?.name + " vs"
            : ""}{" "}
          {row.odd
            ? row?.odd?.fixture_market?.fixture?.competitors[1]?.name
            : ""}
        </>
      ),
      sortable: true,
    },
    {
      name: "Odds",
      selector: (row) => <>{row?.price.toFixed(2)}</>,
      sortable: true,
    },
    {
      name: "Selection",
      selector: (row) => (
        <>
          {row?.odd?.length != 0 && row?.odd != null
            ? _renderOddName(row?.odd?.fixture_market?.fixture, row?.odd)
            : ""}
          <img
            src={`/assets/images/information.png`}
            id={`addtooltip2${row?.id}`}
            className="tooltipCls"
          />
          <UncontrolledTooltip placement="top" target={`addtooltip2${row?.id}`}>
            <p style={{ "margin-bottom": "0px" }}>
              Market: {row.odd ? row.odd.fixture_market.market.name : ""}
            </p>
          </UncontrolledTooltip>
        </>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <a
            className={`btn btn-sm ${
              row?.is_cancel_by_admin
                ? "btn-info"
                : row?.status == null &&
                  parseFloat(row?.void_factor) == parseFloat(0) &&
                  row.out_come_for_canceled == null &&
                  row.is_won == null
                ? "btn-info"
                : row?.status
                ? "btn-success"
                : row?.out_come_for_canceled == "N" ||
                  row?.out_come_for_canceled == "C"
                ? "btn-secondary"
                : parseFloat(row?.void_factor) != parseFloat(0)
                ? "btn-primary"
                : row?.is_won
                ? "btn-success"
                : "btn-danger"
            }`}
          >
            {row?.is_cancel_by_admin
              ? "Void"
              : row?.status == null &&
                parseFloat(row?.void_factor) == parseFloat(0) &&
                row.out_come_for_canceled == null &&
                row.is_won == null
              ? "Pending"
              : row?.status
              ? "Won"
              : row?.out_come_for_canceled == "N" ||
                row?.out_come_for_canceled == "C"
              ? "Cancel"
              : parseFloat(row?.void_factor) != parseFloat(0)
              ? "Void"
              : row?.is_won
              ? "Won"
              : "Lost"}
          </a>
        </>
      ),
      sortable: true,
    },
  ];

  const _renderOddName = (f, odd) => {
    if (odd?.player) {
      return odd?.player?.name;
    }
    let text = odd?.market_spec?.name ?? "";

    text = text.replaceAll("{$competitor1}", f?.competitors[0]?.name);
    text = text.replaceAll("{$competitor2}", f?.competitors[1]?.name);
    if (text.includes("{total}")) {
      text = text.replaceAll("{total}", odd?.special_bet_value);
    } else {
      if (odd?.special_bet_value) {
        text = text + ` {${odd?.special_bet_value}}`;
      }
    }

    return text;
  };

  return (
    <div>
      <div>
        <div className="input-group mb-3 mt-3">
          <span className="input-group-text" id="basic-addon1">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                callPayTicket($(".searchTicketInput").val());
              }}
            >
              <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
            </svg>
          </span>
          <input
            type="text"
            className="form-control searchTicketInput"
            id="formGroupExampleInput"
            placeholder="Ticket / BookID search"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                callPayTicket(e.target.value);
              }
            }}
          />
        </div>
        <div className="d-grid mb-4 ">
          <Link to={"/sports"}>
            <button
              type="button"
              className="btn btn-light btn-lg w-100"
              style={{ whiteSpace: "normal" }}
            >
              <p className="text-start">F1</p>
              <p>SPORTS</p>
            </button>
          </Link>
        </div>
        {/* <div className="d-grid mb-4">
            <Link to={"/virtual"}>
              <button
                  type="button"
                  className="btn btn-light btn-lg w-100"
                  style={{ whiteSpace: "normal" }}
              >
                <p className="text-start">F2</p>
                <p>VIRTUALS</p>
              </button>
            </Link>
          </div>
          <div className="d-grid mb-4">
            <Link to={"/number/games"}>
              <button
                  type="button"
                  className="btn btn-light btn-lg w-100"
                  style={{ whiteSpace: "normal" }}
              >
                <p className="text-start">F3</p>
                <p>NUMBER GAMES</p>
              </button>
            </Link>
          </div> */}
        <div className="d-grid mb-4">
          <Link to={"/online/customers"}>
            <button
              type="button"
              className="btn btn-light btn-lg w-100"
              style={{ whiteSpace: "normal" }}
            >
              <p className="text-start">F4</p>
              <p>ONLINE CUSTOMERS</p>
            </button>
          </Link>
        </div>
        <div className="d-grid mb-4">
          <Link to={"/Bets"}>
            <button
              type="button"
              className="btn btn-light btn-lg w-100"
              style={{ whiteSpace: "normal" }}
            >
              <p className="text-start">F5</p>
              <p>BETS</p>
            </button>
          </Link>
        </div>
        <div className="d-grid mb-4">
          <Link to={"/reports"}>
            <button
              type="button"
              className="btn btn-light btn-lg w-100"
              style={{ whiteSpace: "normal" }}
            >
              <p className="text-start">F6</p>
              <p>REPORTS</p>
            </button>
          </Link>
        </div>
      </div>
      {Object.keys(modalData).length !== 0 ? (
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Ticket {modalData?.id}
                </h5>
                {modalData?.cashier_paid_status != null ? (
                  <div>
                    <span
                      className="badge badge-secondary"
                      style={{ backgroundColor: "green" }}
                    >
                      Paid
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" style={{ color: "#7e7e7e" }}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                {/* -------------------------------------- modal body---------------------- */}

                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="text-white-50  card bg-primary">
                      <div className="card-body">
                        <div className="mb-4 text-white card-title">
                          Total Stake
                        </div>
                        <p className="text-white card-text">
                          {modalData.stake
                            ? parseFloat(modalData.stake).toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text-white-50  card bg-success">
                      <div className="card-body">
                        <div className="mb-4 text-white card-title">
                          Effective Stake
                        </div>
                        <p className="text-white card-text">
                          {modalData.stake_after_tax
                            ? parseFloat(modalData.stake_after_tax).toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text-white-50  card bg-info">
                      <div className="card-body">
                        <div className="mb-4 text-white card-title">Bonus</div>
                        <p className="text-white card-text">
                          {modalData.bonus_amount
                            ? parseFloat(modalData.bonus_amount).toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text-white-50  card bg-warning">
                      <div className="card-body">
                        <div className="mb-4 text-dark card-title">
                          Possible Win
                        </div>
                        <p className="text-dark card-text">
                          {parseFloat(modalData?.to_win).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header bg-light border-bottom">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="card-title text-nowrap">
                                  Bet Type:
                                </div>
                              </div>
                              <div className="col-lg-8 me-t">
                                <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                  {modalData.bets?.length == 1
                                    ? "Single"
                                    : "Multiple"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6" />
                          <div className="col-lg-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="card-title text-nowrap">
                                  Stake Tax:
                                </div>
                              </div>
                              <div className="col-lg-6 me-t">
                                <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                  {parseFloat(
                                    modalData.stake - modalData.stake_after_tax
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="card-title text-nowrap">
                                  Log:
                                </div>
                              </div>
                              <div className="col-lg-9">
                                <div>
                                  <span className="badge-soft-dark me-1 badge badge-secondary badge-pill">
                                    {" "}
                                    {new Date(
                                      Date.parse(modalData?.created_at)
                                    ).toLocaleDateString("en-GB")}{" "}
                                    {new Date(
                                      Date.parse(modalData?.created_at)
                                    ).toLocaleTimeString()}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-2" />
                            </div>
                          </div>
                          <div className="col-lg-6" />
                          <div className="col-lg-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="card-title text-nowrap">
                                  WHT:
                                </div>
                              </div>
                              <div className="col-lg-8 me-t">
                                <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                  {parseFloat(modalData.wht).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3" />
                          <div className="col-lg-6" />
                          <div className="col-lg-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="card-title text-nowrap">
                                  Total Odds:
                                </div>
                              </div>
                              <div className="col-lg-8 me-t">
                                <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                  {parseFloat(modalData.total_odds).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <DataTable
                          defaultSortFieldId={1}
                          style={{ width: "800px" }}
                          columns={modalColumns}
                          data={modalData?.bets}
                          paginationPerPage={5}
                          pagination
                        />
                        {modalData.status == 1 ||
                        (modalData.status == 2 &&
                          modalData.is_cancel_by_cashier == 0) ? (
                          modalData.cashier_paid_status == null ? (
                            <div>
                              <input
                                type="text"
                                placeholder="PIN"
                                id={`pinText${modalData?.id}`}
                                className="pin-textbox"
                                spellCheck="false"
                                data-ms-editor="true"
                                maxLength={4}
                              />
                              <button
                                className="pay-btn"
                                style={{ height: "30px" }}
                                onClick={() => {
                                  payTicket(
                                    modalData?.id,
                                    document.getElementById(
                                      `pinText${modalData?.id}`
                                    ).value
                                  );
                                }}
                              >
                                {modalData.status == 1
                                  ? "Pay Ticket"
                                  : "Refund"}
                              </button>
                            </div>
                          ) : (
                            ""
                          )
                        ) : modalData.cashier_paid_status == null &&
                          modalData.status == null ? (
                          <div>
                            {diffMinutes(modalData?.created_at) <= 5 &&
                            modalData.is_cancel_by_cashier == 0 ? (
                              <button
                                className="btn btn-secondary"
                                style={{
                                  margin: "5px 0 0px 0",
                                  fontSize: "inherit",
                                }}
                                onClick={() => {
                                  cancelTicket(modalData?.id);
                                }}
                              >
                                Cancel
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {/*{*/}
                        {/*  (modalData.status==1 || (modalData.status==2 && modalData.is_cancel_by_cashier==0) ) ?*/}
                        {/*      (modalData.pin==null) ?*/}
                        {/*      <div>*/}
                        {/*        <input type="text" placeholder="PIN" id={`pinText${modalData?.id}`} className="pin-textbox"*/}
                        {/*               spellCheck="false" data-ms-editor="true" maxLength={4} />*/}
                        {/*        <button className="pay-btn" style={{height:"30px"}} onClick={() => { payTicket(modalData?.id,document.getElementById(`pinText${modalData?.id}`).value) }}>{modalData.status == 1 ? "Pay Ticket" : "Refund"}</button>*/}
                        {/*      </div>*/}
                        {/*      : ''*/}
                        {/*      :*/}
                        {/*      ''*/}
                        {/*}*/}

                        {/*<div className="row react-bootstrap-table-pagination">*/}
                        {/*  <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">*/}

                        {/*  </div>*/}
                        {/*  <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6 d-flex justify-content-end">*/}
                        {/*    <ul className="pagination react-bootstrap-table-page-btns-ul">*/}
                        {/*      <li className="active page-item" title={1}>*/}
                        {/*        <a href="#" className="page-link">*/}
                        {/*          1*/}
                        {/*        </a>*/}
                        {/*      </li>*/}
                        {/*    </ul>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {successPin ? (
        <div
          className="modal fade"
          id="exampleModalCenter1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Paid Confirmation
                </h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  // data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h5>Ticket mark as paid successfully</h5>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
